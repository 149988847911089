import React from "react";
import styled from "styled-components";

import { ThemeColors } from "../../theme/theme";

const Spinner = styled.div<{ size: number; color: ThemeColors }>`
  border: 2px solid
    ${({ theme, color }) => (color ? theme[color].color : theme.primary.color)};
  width: ${({ size, theme }) => size || 4 * theme.spacing}px;
  height: ${({ size, theme }) => size || 4 * theme.spacing}px;
  border-radius: 50%;
  border-left-color: rgba(0, 0, 0, 0.1);
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
