import { createGlobalStyle } from "styled-components";

export interface Theme {
  body: string;
  text: string;
  primary: {
    color: string;
    contrast: string;
  };
  secondary: {
    color: string;
    contrast: string;
  };
  success: {
    color: string;
    contrast: string;
  };
  danger: {
    color: string;
    contrast: string;
  };
  light: {
    color: string;
    contrast: string;
  };
  dark: {
    color: string;
    contrast: string;
  };
  font: {
    heading: string;
    paragraph: string;
  };
  breakpoints: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  typography: {
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
    p: number;
  };
  spacing: number;
}

export type ThemeColors =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "light"
  | "dark";

export const GlobalStyles = createGlobalStyle`

  * {
    font-family: ${({ theme }) => theme.font}, sans-serif;
    margin: 0;
  }

  html, body {
    width: 100vw;
    overflow-x: hidden;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;

export const colorOpacity = (hexa: string, opacity: number) => {
  if (!hexa || hexa[0] !== "#" || hexa.length !== 7) return ``;

  const red = parseInt(hexa.slice(1, 3), 16);
  const green = parseInt(hexa.slice(3, 5), 16);
  const blue = parseInt(hexa.slice(5, 7), 16);

  return `
    rgba(${red}, ${green}, ${blue}, ${opacity});
  `;
};

export const lightTheme: Theme = {
  body: "#f1f1f1",
  text: "#121620",
  primary: {
    color: "#d6a593",
    contrast: "#fff",
  },
  secondary: {
    color: "#78999e",
    contrast: "#fff",
  },
  success: {
    color: "#4CAF50",
    contrast: "#fff",
  },
  danger: {
    color: "#f44336",
    contrast: "#fff",
  },
  light: {
    color: "#f5f5f5f",
    contrast: "#222",
  },
  dark: {
    color: "#2f2f2f",
    contrast: "#fff",
  },
  font: {
    paragraph: "Montserrat",
    heading: "Montserrat",
  },
  breakpoints: {
    xs: 576, //Mobile - portrait
    sm: 768, //Mobile - landscape
    md: 992, //Tablet
    lg: 1200, //Desktop
    xl: 1400, //Desktop XL
  },
  typography: {
    h1: 48,
    h2: 36,
    h3: 32,
    h4: 28,
    h5: 22,
    h6: 18,
    p: 15,
  },
  spacing: 8,
};

export const darkTheme: Theme = {
  ...lightTheme,
  body: "#1f2023",
  text: "#f5f5f5",
};
